.helper-container {
    width: 100%;
    left: 0;
    background-color: #D59847;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
    padding: 2rem 0;
  }
  
  .helper-text {
    font-size: 2rem;
    color: white;
    text-decoration: 1px underline;
    text-underline-offset: 1rem;
  }
  
  .footer-about {
    padding: 3rem 2rem 0rem 2rem;
    background-color: black;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    gap: 2rem;

    .footer-logo {
      height: 5rem;
      width: auto;
      cursor: pointer;
    }
  }

  .footer-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: black;
    // height: 60vh;
    color: white;
    padding: 2rem; 
  
    .footer-about-text {
      font-size: 1.2rem; 
      margin-bottom: 1rem;
    }
  
    .footer-col {
      display: flex;
      flex-direction: column; 
      align-items: flex-start;
      gap: 1rem; 
      margin: 1rem; 

      .footer-link {
        font-size: 0.9rem;
        text-decoration: none;
        color: #FFFFFF;
        margin: 0;
        cursor: pointer;

        &.font-1rem {
          font-size: 1rem;
        }

        &:hover {
          color: #D59847;
        }
      }
  
      .footer-col-heading {
        // position: relative;
        margin-bottom: 1rem;
        border-bottom: 1px solid #FFFFFF;
        padding-bottom: 0.5rem;
        font-size: 1rem;
  
        // &::after {
        //   content: '';
        //   display: block;
        //   width: 100%;
        //   height: 2px;
        //   background-color: white;
        //   position: absolute;
        //   bottom: -5px; 
        //   left: 0;
        // }
      }
  
      div {
        margin-bottom: 0.5rem; 
      }
    }
  }
  
  .footer-bottom-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: black;
    color: white;
    padding: 2rem;
  
    .footer-icons {
      display: flex;
      justify-content: space-between;
      width: 100%;
      max-width: 300px; 
      margin-bottom: 1rem;
      cursor: pointer;
  
      svg {
        color: white;
        font-size: 1.5rem; 
      }
    }
  
    .footer-line {
      width: 100vw;
      height: 1px;
      background-color: white;
      margin-bottom: 1rem;
    }
  
    .footer-text {
      text-align: center;
      a {
        color: white;
        text-decoration: underline;
      }
    }
  }

  .footer-plus-icon {
    display: none;
  }
  
@media screen and (max-width: 961px) {

  .helper-text {
    font-size: 1.5rem;
  }

  .footer-about {
    text-align: left;
    padding: 1.25rem;
    flex-direction: column;
    align-items: flex-start;

    .footer-logo {
      height: 4rem;
      width: auto;
    }
  }

  .footer-container {
    padding: 1.25rem;
    flex-direction: column;

    .footer-col {
      margin: 0;
      padding: 0 0 1rem 0;

      &.row {
        flex-direction: row;
        margin-bottom: 1rem;
      }

      .footer-col-content {
        width: 100%;

        .footer-col-heading {
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
        }

        .unhide-drop-down {
          display: block;

          &.footer-col-expand {
            display: none;
          }
        }
      }
    }
  }

  .footer-plus-icon {
    display: block;
    transition: all linear 0.3s;
  }
}