.navbar-container {
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 1000;

  .nav-bar-date {
    background-color: #0C0C0C;

    .nav-date-text {
      color: #FFFFFF;
      font-size: 1rem;
      margin: 0;
      padding: 1rem 2rem;
    }
  }
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;

  .navbar-logo-cont {
    display: flex;
    align-items: center;
    gap: 1rem;
    cursor: pointer;

    .navbar-logo {
      height: 4rem;
      width: auto;
    }

    .text-logo {
      height: 1.5rem;
      width: auto;
    }
  }

  .menu-icon {
    display: none;
  }

  .nav-links {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 10px;
  
    li {
      margin-left: 20px;
      &.active {
        box-shadow: 0 2px 4px rgba(166, 166, 166, 0.5); 
      
      }
  
      div {
        text-decoration: none;
        color: #D59847;
        cursor: pointer;
        font-weight: 600;
  
        &:hover {
          color: #ff9100;
        }
      }
    }
  }
  
  
}

@media screen and (max-width: 961px) {

  .navbar-container {

    .nav-bar-date {

      .nav-date-text {
        font-size: 0.75rem;
        padding-left: 1rem;
      }
    }
  }

  .navbar {
    justify-content: flex-start;
    gap: 1rem;

    .menu-icon {
      display: block;
    }

    .navbar-logo-cont {
      display: flex;
      align-items: center;
      gap: 0.75rem;
      cursor: pointer;

      .navbar-logo {
        height: 3rem;
        width: auto;
      }
  
      .text-logo {
        height: 1rem;
        width: auto;
      }
    }


    .nav-links {
      display: none;
    }
  }
}

.ant-dropdown-menu {
  min-width: 160px;

  a {
    color: #000;
    text-decoration: none;

    &:hover {
      color: #D59847;
    }
  }
}

.drawer-menu {
  list-style: none;
  padding: 0;

  li {
    margin-bottom: 1rem;

    div {
      text-decoration: none;
      color: #D59847;
      cursor: pointer;
      font-weight: 600;

      &:hover {
        color: #ff9100;
      }
    }

    &.active div {
      font-weight: bold;
    }
  }
}
