.insights-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 100px 50px 0 50px;
    padding-top: 30px;

    .insights-title {
        font-size: 2.5rem;
        color: #D59847;
        margin-top: 3px;
        margin-left: 50px;
    }
    .insights-content-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center; 
    }
    
    .insights-content {
        display: flex;
        flex-direction: column; 
        margin-left: 50px;
    }
    
    .insights-line {
        display: flex;
        align-items: center; 
        justify-content: space-between;
        margin-bottom: 1rem;
        text-decoration: none;
        margin-right: 2rem;
    }
    
    .insights-numbering {
        font-size: 2rem;
        color: white;
        border-radius: 50%;
        background-color: #D59847;
        aspect-ratio: 1 / 1;
        height: 2.5rem;
        width: 2.5rem;
        display: grid;
        place-items: center;
    }
    
    .insights-data {
        font-size: 1.75rem;
        margin: 20px;
        color: #0C0C0C;
    }
    
    .insights-logo {
        max-width: 35%; 
        margin-right: 100px;
    }

}

.operational-plan-container {
    padding: 2rem;

    .page-heading {
        font-size: 2.75rem;
        font-weight: 700;
        margin-bottom: 3rem;
        color: #0C0C0C;

        .number {
            padding: 0 1rem;
            background-color: #D59847;
            border-radius: 50px;
            box-shadow: 0 4px 4px #00000040;
            color: #FFFFFF;
        }
    }

    .operational-container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2rem;
        margin-bottom: 4rem;

        .phase-container {
            background-color: #444444;
            padding: 1rem;
            border-radius: 20px;
            cursor: pointer;
            transition: all linear 0.2s;
            min-width: 390px;
            flex: 1;

            &:hover {
                background-color: #D59847;
                box-shadow: -7px 11px 17px #00000040;
                transition: all linear 0.2s;
            }

            .phase-title {
                font-size: 2.5rem;
                font-weight: 700;
                color: #FFFDFD;
                margin: 0 0 1.5rem 0;
            }
    
            .pointers {
                font-size: 1.5rem;
                font-weight: 700;
                color: #FFFDFD;
                margin: 0 0 1.5rem 0;
            }
    
            .learn-more {
                background-color: #EAF5FF;
                color: #161515;
                font-size: 1.25rem;
                font-weight: 400;
                padding: 0.5rem 1.5rem;
                border-radius: 20px;
                text-decoration: none;
                margin-top: 3rem;
            }
        }

        .right-conatiner {
            display: flex;
            flex-wrap: wrap;
            max-width: max-content;
            gap: 2rem;
        }
    }

    .prev-button {
        font-size: 1.5rem;
        font-weight: 700;
        color: #0C0C0C;
        background-color: #E8E8E8;
        border-radius: 10px;
        padding: 0.25rem 0.5rem;
        text-decoration: none;
    }
}

.insight-hero-container {
    position: relative;
    background-color: #0C0C0C;

    .hero-heading {
        // position: absolute;
        font-size: 3.5rem;
        font-weight: 700;
        color: #FFFFFE;
        padding: 6rem 4rem;
        margin: 0;
    }
}

.insight-page-container {
    padding: 2rem;

    .page-buttons-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 3rem;

        .page-btn {
            color: #FFFDFD;
            text-decoration: none;
            background-color: #444444;
            border-radius: 10px;
            font-size: 2.5rem;
            font-weight: 700;
            padding: 0.25rem 1rem;

            &:hover {
                background-color: #D59847;
                transition: all linear 0.2s;
            }

            &.active {
                background-color: #D59847;
                box-shadow: 0 4px 4px #00000040;
            }
        }
    }

    .data-container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .insight-data-container {

            .insight-data {
                padding: 1rem 2.25rem;
                margin: 0 1rem 3rem 1rem;

                .data-heading {
                    font-size: 2.25rem;
                    font-weight: 700;
                    color: #D59847;
                    margin: 0 0 1.5rem 0;
                }

                .list-pointers {
                    list-style-type: disc;

                    .list-item {
                        font-size: 1.5rem;
                        font-weight: 600;
                        margin-bottom: 1.5rem;

                        &::marker {
                            color: #D59847;
                        }

                        .brown {
                            color: #D59847;
                        }
                    }
                }
            }
        }
    }
}

.button-container {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    margin: 1rem;
  }
  
  .nav-button {
    font-size: 1.5rem;
    font-weight: 700;
    color: #000000;
    background-color: #E8E8E8;
    border-radius: 10px;
    padding: 0.5rem 1rem;
    text-decoration: none;
    display: flex;
    align-items: center;
    
    &.prev-button {
      margin-right: auto;
    }
    
    &.next-button {
      margin-left: auto;
    }
  }
  
  .nav-button .anticon {
    font-size: 2rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  
  .mainActivities-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: 20px;
    padding: 40px;
  }

  .mainActivities-item {
    background-color: #e0ecfe;
    padding: 20px;
    text-align: center;
    color: black;
    border-radius: 10px;
    font-weight: 700;
    font-size: 24px;
    line-height: 56.42px;
    display: grid;
    place-items: center;
  }
  
  .strategicwork-heading {
    display: flex;
    align-items: center; 
    margin-left: 4rem;
    padding-top: 5rem;
}

.strategicwork-numbering {
    font-size: 2rem;
    color: white;
    border-radius: 50%;
    background-color: #D59847;
    aspect-ratio: 1 / 1;
    height: 2.75rem;
    width: 2.75rem;
    display: grid;
    place-items: center;
    margin-right: 1rem;
}

.strategicwork-data {
    font-size: 2.2rem;
}

.strategicwork-content{
    display: flex;
    align-items: center; 
    margin-left: 5.5rem;
}

.strategicwork-ul {
    margin-top: 30px;
    font-size: 1.2rem;
    list-style-type: disc;
    position: relative;
    padding-left: 20px; 
  }
  
  .strategicwork-li {
    margin-top: 30px;
    position: relative;
    // padding-left: 20px; 

    &::marker {
      color: #D59847;
      font-size: 1.5rem;
    }
    
    // &::before {
    //   content: '';
    //   width: 18px;
    //   height: 18px;
    //   background-color: #D59847;
    //   border-radius: 50%;
    //   display: inline-block;
    //   // position: absolute;
    //   // left: -20px;
    //   // top: 30%;
    //   // transform: translateY(-50%);
    // }
  }

.threestep-heading{
  display: flex;
  align-items: center; 
  margin-left: 5rem;
  padding-top: 5rem;
}
.threestep-step{
  font-size: 1.5rem;
  font-weight: 600;
}
.threestep-data{
  font-size: 1.5rem;
  color: #3A4DE9;
  padding-left: 5px;
}
.line{
  padding-left: 2rem;
  padding-right: 2rem;
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 1.5px;
    background-color: #BAB7B7;
    margin: 8px auto 0;
  }
}
.image-container {
    height: 50vh;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  
    img {
      width: 100%;
      position: absolute;
      top: -16rem;
      left: 0%;
      height: auto;
      opacity: 0.5;
      transform: scaleX(-1);
      z-index: -1;
    }
  }
  
  .fundraising-numbering {
    font-size: 3.5rem;
    color: white;
    border-radius: 100px;
    background-color: #D59847;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
    padding: 0rem 1.35rem;
  }
  
  .fundraising-data {
    font-size: 3.5rem;
    font-weight: 600;
  }
  
  .heading-wrapper {
    padding: 5rem 8rem 5rem 7rem;
    background: linear-gradient(
      90deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 212, 255, 0) 12%,
      rgba(0, 212, 255, 0.45281862745098034) 50%,
      rgba(0, 212, 255, 0) 88%,
      rgba(0, 212, 255, 0) 99%
    );
  
    p {
      font-size: 2rem;
    }
  }
  
  .subheading-wrapper {
    margin-top: 4rem;
    padding-left: 7rem;
    align-items: center;
    background: linear-gradient(
      90deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 212, 255, 1) 0%,
      rgba(0, 212, 255, 0.45281862745098034) 35%,
      rgba(0, 212, 255, 0) 79%,
      rgba(0, 212, 255, 0) 99%
    );

    &.pink{
      background: linear-gradient(
      90deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 0%,
      rgb(163, 101, 188) 0%,
      rgba(111, 0, 255, 0.453) 35%,
      rgba(0, 212, 255, 0) 79%,
      rgba(0, 212, 255, 0) 99%
    );
    }
    &.orange{
      background: linear-gradient(
      90deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 0%,
      rgb(255, 136, 0) 0%,
      rgba(255, 102, 0, 0.453) 35%,
      rgba(0, 212, 255, 0) 79%,
      rgba(0, 212, 255, 0) 99%
    );
    }
    &.green{
      background: linear-gradient(
      90deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 0%,
      rgb(0, 255, 162) 0%,
      rgba(0, 255, 195, 0.453) 35%,
      rgba(0, 212, 255, 0) 79%,
      rgba(0, 212, 255, 0) 99%
    );
    }

    display: flex;
    align-items: center;
    p {
      font-size: 2rem;
      display: flex;
      align-items: center;
    }
  
    .star-icon {
      color: #D59847;
      margin-right: 1rem;
      font-size: 2rem;
    }
  }
  .subheading-content-wrapper{
    margin-top: 4rem;
    padding-left: 7rem;
    padding-right: 7rem;
    display: flex;
    flex-direction: column;
  }
  
  .subheading-data{
    display: flex;
    align-items: flex-start;
    margin-bottom: 4rem;
    word-spacing: 7px; 
    line-height: 2rem;
    &.eccsa{
      margin-top: 1rem;
      margin-bottom: 1.25rem;
    }
  }
  .ending-data{
    font-weight: 600;
    font-size: 24px;
    line-height: 29.23px;
  }
  .fundraising-body-numbering {
    font-size: 1.2rem;
    height: 2rem;
    color: white;
    border-radius: 100px;
    background-color: #D59847;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 2rem; 
    aspect-ratio: 1 / 1;
  }
  
  .fundraising-body-data {
    font-size: 1.4rem;
    font-weight: 400;
    margin: 0;
  }

  .insights-section {
    text-align: center;
    padding: 20px;
  
    .section-heading {
      display: flex;
      align-items: center;
      flex-direction: column;
      text-align: center;
      margin-bottom: 5rem;
    
      h2 {
        font-size: 45px;
        color: #D59847;
        border-bottom: 2px solid #D59847;
        padding-bottom: 0.5rem;
        position: relative;
        display: inline-block;
      }
    
      span {
        height: 2px;
        background-color: #D59847;
        width: 40rem; 
      }
    }
    
  
    .insights-cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 50px;
    }
  
    .card {
      width: 220px;
      height: 220px;
      border: 1px solid #ccc;
      // border-radius: 4px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: white;
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      opacity: 0.3;
  
      &.active {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        opacity: 1;
      }
  
      .card-content {
        padding: 20px;
        text-align: left;
  
        p {
          font-size: 1rem;
          color: #333;
          margin: 0;
          font-weight: bold;
          line-height: 2rem;
        }
      }
  
      .card-footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 10px;
        // border-radius: 0 0 8px 8px;
  
        .read-more {
          background: none;
          border: none;
          color: inherit;
          cursor: pointer;
          font-size: 14px;
          text-transform: uppercase;
          display: flex;
          align-items: center;
        }
      }
    }
}

@media screen and (max-width: 961px) {
  .insights-container {
    margin: 0;
    padding: 1.25rem;

    .insights-title {
      margin: 0;
    }

    .insights-content-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .insights-content {
        margin: 0;

        .insights-line {
          margin: 1.25rem 0;
          
          .insights-numbering {
            font-size: 1rem;
            height: 1.5rem;
            width: 1.5rem;
            margin-right: 1rem;
          }

          .insights-data {
            font-size: 1.2rem;
            margin: 0;
          }
        }
      }

      .insights-logo {
        max-width: 100%;
        margin: 1rem 0 0 0;
      }
    }
  }
}
  
@media screen and (max-width: 961px) {
  .strategicwork-container {

    .main-activity-image {
      width: 100%;
      height: auto;
    }

    .mainActivities-grid {
      margin-top: 0;
      grid-template-columns: repeat(1, 1fr);

      .mainActivities-item {
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }
  }

  .strategicwork-heading {
    margin: 0;
    padding: 2rem 1rem;

    .strategicwork-numbering {
      font-size: 1.25rem;
      height: 2rem;
      width: 2rem;
    }

    .strategicwork-data {
      font-size: 1.5rem;
    }
  }

  .strategicwork-content {
    margin: 0;
    padding: 0rem 1rem;

    .strategicwork-ul {
      margin: 0;
      font-size: 1rem;
      padding-left: 1rem;

      .strategicwork-li {
        margin: 0 0 1rem 0;

        &::marker {
          font-size: 1.25rem;
        }
      }
    }
  }

  .button-container {
    margin: 0;

    .nav-button {
      font-size: 1rem;
      padding: 0.3rem 0.5rem;

      &.prev-button {
        .anticon {
          margin: 0 0.5rem 0 0;
        }
      }
      
      &.next-button {
        .anticon {
          margin: 0 0 0 0.5rem;
        }
      }

      .anticon {
        font-size: 1rem !important;
      }
    }
  }

  .image-container {
    height: 200px;
    
    img {
      top: 0;
    }

    .fundraising-numbering {
      font-size: 1.5rem;
      padding: 0;
      height: 1.75rem;
      width: 1.75rem;
      aspect-ratio: 1 / 1;
    }

    .fundraising-data {
      font-size: 1.5rem;
    }
  }

  .heading-wrapper {
    padding: 1rem;

    p {
      font-size: 1.25rem;
      margin: 0;
    }
  }

  .subheading-wrapper {
    padding: 1rem;
    margin-top: 1.5rem;
    
    p {
      font-size: 1.25rem;
    }
  }

  .subheading-content-wrapper {
    padding: 0 1rem;
    margin-top: 1.5rem;

    .subheading-data {
      align-items: flex-start;
      margin-bottom: 1.5rem;
      
      .fundraising-body-numbering {
        aspect-ratio: 1 / 1;
        height: 1.5rem;
        // width: 1.5rem;
        font-size: 1rem;
        margin-right: 1rem;
        margin-top: 0.5rem;
      }

      .fundraising-body-data {
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }

    .ending-data {
      font-size: 1rem;
    }
  }

  .section-heading {

    h2 {
      font-size: 1.5rem !important;
      border-bottom: 2px solid #D59847;
      padding-bottom: 0.5rem;
      margin: 0 0 1.5rem 0;
    }

    span {
      display: none;
    }
  }

  .operational-plan-container {
    padding: 1rem;

    .page-heading {
      font-size: 1.5rem;
      display: flex;
      align-items: center;
      gap: 1rem;

      .number {
        aspect-ratio: 1 / 1;
        height: 2rem;
        padding: 0;
        display: grid;
        place-items: center;
      }
    }

    .operational-container {
      flex-direction: column;
      align-items: center;
      gap: 2rem;

      .phase-container {
        min-width: 250px;
        max-width: 250px;
        
        .phase-title {
          font-size: 1.5rem;
          margin: 0.5rem 0;
        }

        .pointers {
          font-size: 1rem;
          margin: 0.5rem 0;
        }

        .learn-more {
          margin-top: 1rem;
          font-size: 1rem;
          display: block;
          width: max-content;
        }
      }

      .right-conatiner {
        flex-direction: column;
        align-items: center;
      }
    }
  }

  .insight-hero-container {
    padding: 2rem 0 2rem 1rem;

    .hero-heading {
      padding: 0;
      font-size: 1.75rem;
    }
  }

  .insight-page-container {
    padding: 1rem;

    .page-buttons-container {
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 1rem;

      .page-btn {
        font-size: 1rem;
      }
    }

    .data-container {

      .page-nav-btn {
        display: none;
      }

      .insight-data-container {
        // width: calc(100vw - 2rem);
        
        .insight-data {
          margin: 0 0 1.5rem 0;
          padding: 1rem;

          .data-heading {
            font-size: 1.25rem;
          }

          .list-pointers {
            font-size: 1rem;
            padding: 0 0 0 1rem;

            .list-item {
              font-size: 1rem;
            }
          }
        }
      }
    }
  }

  .threestep-heading {
    margin: 0;
    margin-bottom: 1rem;
    padding-left: 1rem;

    .threestep-step {
      display: block;
      font-size: 1.25rem;
      width: 80px;
    }

    .threestep-data {
      display: block;
      font-size: 1.25rem;
    }
  }
}

@media screen and (max-width: 480px) {
  .insights-section {

    .insights-cards {

      .card {

        .card-content {
          padding: 0.75rem;
          font-size: 0.9rem;
        }
      }
    }
  }

  .subheading-content-wrapper {
    padding: 0;

    .padding-lr {
      padding: 0 1rem !important;
    }

    .subheading-data {
      flex-direction: column;
      gap: 0.5rem;

      &.blue-cont {
        padding: 1rem 0 !important;
      }

      .fundraising-body-numbering {
        border-radius: 0;
        background-color: transparent;
        background-image: linear-gradient(to right, #D59847, #d5974700);
        padding: 0.1rem 1.25rem 0.1rem 1rem;
        justify-content: flex-start;
        font-size: 1.25rem;
      }
      
      .fundraising-body-data {
        padding: 0 1rem;
      }
    }
    
    .ending-data {
      padding: 0 1rem;
    }
  }
}