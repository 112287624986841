.goal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 50px 0 50px; 
    
    .goal-logo {
      max-width: 50%;
    margin-bottom: 20px; 
    margin-top: 50px;
  }
  
  .goal-content {
    text-align: left;
    width: 100%;
    
    p {
      line-height: 2rem;
      margin: 2rem 0;
    }
  }
  
  .goal-title {
    font-size: 2.5rem;
    color: #D59847;
    margin-top: 20px;
  }
  
  p {
    margin-top: 50px; 
    font-size: 1.2rem;
  }
  
  .goal-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    gap: 20px; 
    margin: 3rem 0; 
  }
  
  .goal-item {
    background-color: #e0e0e0;
    padding: 0.5rem 0;
    text-align: center;
    color: black;
    border-radius: 10px;
    font-weight: 700;
    display: grid;
    place-items: center;
  }  
}

@media screen and (max-width: 961px) {
  .goal-container {
    margin: 0;
    padding: 0 1rem;

    .goal-logo {
      max-width: 100%;
      margin: 1rem 0;
    }

    .goal-content {
      
      .goal-title {
        font-size: 1.5rem;
        margin-bottom: 1rem;
      }

      p {
        font-size: 1rem;
        margin-top: 1rem;
      }

      .goal-grid {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}