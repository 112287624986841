.team-profile-hero-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem 4rem 0 4rem;

    &.daily-ramasia {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;

        .left-intro {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 2rem;
        }

        .right-intro {
            margin-left: 1.75rem;
            margin-top: 2rem;

            .intro-text {
                font-size: 1.25rem;
                font-weight: 600;
                margin: 0;
                line-height: 2rem;

                .brown {
                    color: #D59847;
                }
            }
        }
    }

    .cirlce-bg {
        position: relative;

        &::before, &::after {
            content: "";
            position: absolute;
            border-radius: 500px;
            width: 400px;
            height: 375px;
            z-index: -1;
            background: transparent;
        }

        &::before {
            top: 10%;
            left: -6%;
            border: 2px solid;
            border-image: linear-gradient(#B87A27, #FFFFFF, #B87A27) 1;
        }

        &::after {
            top: 12%;
            left: -8%;
            border: 2px solid;
            border-image: linear-gradient(#D59847, #FFFFFF, #D59847) 1;
        }
    }

    .hero-image {
        height: 450px;
        width: 350px;
        position: relative;
        overflow: hidden;

        .team-hero-img {
            position: absolute;

            &.gopal-girish {
                height: 450px;
            }

            &.daily-ramasia {
                height: 450px;
            }

            &.jhon-bosco {
                width: 350px;
                top: -20% !important;
                left: 0 !important;
            }

            &.philip-uzuma {
                width: 350px;
                height: auto !important;
                top: 0 !important;
            }
        }
    }

    .title {
        font-size: 2rem;
        font-weight: 600;
        margin-bottom: -0.75rem;
    }

    .designation {
        font-size: 1.5rem;
        font-weight: 700;
        color: #CE6767;
        text-align: center;
        margin-bottom: 3rem;
    }
}

.team-profile-body-container {
    padding: 0 4rem 4rem 4rem;

    .label-sub-heading {
        font-size: 1.5rem;
        font-weight: 600;
        color: #DA7656;
        margin: 1.75rem 0;

        &.black {
            color: #000000;
        }

        &.black-underline {
            color: #000000;
            border-bottom: 1px solid black;
            padding-bottom: 1rem;
            margin: 3rem 0;
        }
    }

    .label-heading {
        font-size: 2.75rem;
        font-weight: 700;
        color: #D59847;
        margin-top: 4rem;

        &::after {
            border-block-start: 2px solid #D59847;
            border-block-start-color: #D59847;
        }
    }

    .mark-sheet-cont {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 2rem 0;

        .mark-sheet-desc {
            margin-right: 1rem;

            .label-sub-heading {
                font-size: 1.5rem;
            }

            .label-text {
                font-size: 1.25rem;
            }
        }

        .mark-sheet-img {
            width: 60%;
            border: 5px solid #D9D9D9;
        }
    }

    .pink-container {
        background-color: #FCF3E7;
        padding: 2rem;

        .read-more {
            display: block;
            width: max-content;
            text-decoration: none;
            color: #FFFDFB;
            font-size: 2rem;
            font-weight: 600;
            background-color: #D59847;
            border-radius: 7px;
            padding: 0.25rem 1rem;
            margin-top: 1.75rem;
        }
    }

    .about-more-cont {
        display: flex;
        align-items: flex-start;
        gap: 2rem;
    }

    .label-text {
        font-size: 1.5rem;
        font-weight: 600;
        margin: 0 0 0.5rem 0;
        line-height: 2.5rem;

        &.light {
            font-size: 1.25rem;
            font-weight: 400;
        }

        &.timeline {
            width: 100%;
        }
    }

    .label-bullet-text {
        font-size: 1.5rem;
        font-weight: 600;
        margin: 0 0 0.5rem 0;
        list-style-type: disc;

        &.light-weight {
            font-weight: 400;
        }

        &.light {
            font-size: 1.25rem;
            font-weight: 400;
        }

        .bullet-item {
            margin-bottom: 1.5rem;

            &::marker {
                color: #D59847;
                font-size: 1.6rem;
                padding-top: 1rem;
            }

            &.check-list {
                list-style-type: none;

                &::before {
                    content: '✓';
                    margin-right: 1rem;
                    font-weight: 700;
                }
            }
        }
    }

    .expirence-container {
        display: flex;
        align-items: center;
        gap: 2rem;
        margin-bottom: 4rem;

        &.education {
            align-items: center;
        }

        .date-container {
            position: relative;
            min-width: 150px;
            height: 150px;

            &.timeline {
                width: 150px;

                .start-date {
                    display: grid;
                    place-items: center;
                    padding: 0;
                    width: 80px;
                    height: 80px;
                }

                .end-date {
                    display: grid;
                    place-items: center;
                    padding: 0;
                    width: 80px;
                    height: 80px;
                }
            }

            .start-date {
                width: 50px;
                height: 50px;
                background-color: #DFDFDF;
                padding: 1rem;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 0;
                text-align: center;
                display: grid;
                place-items: center;
            }

            .end-date {
                width: 50px;
                height: 50px;
                background-color: #000000;
                color: #FFFFFF;
                padding: 1rem;
                position: absolute;
                bottom: 0;
                right: 0;
                z-index: 1;
                text-align: center;
                display: grid;
                place-items: center;
            }
        }

        .date-container-education {
            width: 150px;
            height: 100px;
            background-color: #DFDFDF;
            display: grid;
            place-items: center;

            .start-date-education {
                font-size: 1.25rem;
                font-weight: 600;
                margin: 0;
            }
        }
    }
}

.blue-container {
    padding: 2rem 4rem;
    background-color: #E9F6FD;

    .label-text {
        font-size: 1.25rem;
        font-weight: 400;
        margin: 0;
        line-height: 1.75rem;
    }
}

.bottom-btn-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 4rem;
    margin-bottom: 3rem;

    .pages-button-cont {
        display: flex;
        align-items: center;
        gap: 3rem;

        .page-btn {
            font-size: 1.25rem;
            font-weight: 400;
            color: #000000;
            text-decoration: none;

            &.active-btn {
                color: #FFFFFF;
                background-color: #424242;
                border-radius: 50%;
                aspect-ratio: 1 / 1;
                height: 1.75rem;
                width: 1.75rem;
                display: grid;
                place-items: center;
            }
        }
    }

    .navigate-button {
        font-size: 2rem;
        font-weight: 600;
        color: #FFFFFF;
        padding: 0.25rem 1rem;
        border: none;
        background-color: #D59847;
        border-radius: 7px;
        cursor: pointer;
    }
}

.footer-banner {
    background-color: #D59847;
    padding: 2rem;
    position: relative;

    &.blue {
        background-color: #D6EFEB80;
        margin-bottom: 4rem;

        .banner-text {
            color: #000000;
        }
    }

    .banner-text {
        color: #FFFFFF;
        font-size: 2rem;
        font-weight: 600;
        margin: 0;
        text-align: center;
        text-decoration: none;
        display: block;
    }

    .download-profile {
        position: absolute;
        bottom: 1rem;
        right: 1rem;
        cursor: pointer;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 0.5rem;

        .download-text {
            font-size: 1rem;
            font-weight: 600;
            color: #FFFFFF;
            margin: 0;
        }
    }
}


@media screen and (max-width: 961px) {
    .team-profile-hero-container {
        padding: 1rem 1rem 0 1rem;

        &.daily-ramasia {
            flex-direction: column;
            align-items: center;

            .left-intro {
                padding: 0;

                h4 {
                    font-size: 1rem !important;
                }
            }

            .right-intro {
                margin-left: 0;

                .intro-text {
                    font-size: 1rem;
                }
            }
        }

        .cirlce-bg {
            position: relative;
    
            &::before, &::after {
                width: 225px;
                height: 175px;
            }
    
            &::before {
                top: 5%;
                left: -6%;
            }
    
            &::after {
                top: 8%;
                left: -8%;
            }
        }

        .hero-image {
            width: 200px;
            height: 200px;

            .team-hero-img {
                width: 100%;
                height: auto !important;
                top: 0 !important;
                left: 0 !important;

                &.philip-uzuma {
                    width: 200px;
                    height: auto !important;
                    top: 0 !important;
                }

                &.gopal-girish {
                    height: 250px !important;
                    width: auto;
                }

                &.jhon-bosco {
                    height: auto !important;
                    width: 200px;
                    top: -35% !important;
                }
            }
        }

        .title {
            font-size: 1.5rem;
        }
    
        .designation {
            font-size: 1rem;
        }
    }

    .team-profile-body-container {
        padding: 0 1rem 1rem 1rem;

        .label-sub-heading {
            font-size: 1.25rem;
        }

        .label-heading {
            font-size: 1.5rem;
        }

        .label-text {
            font-size: 1rem;
            line-height: 1.75rem;
            width: 95%;

            &.light {
                font-size: 1rem;
            }
        }

        .mark-sheet-cont {
            flex-direction: column;
            align-items: flex-start;
            padding: 0;
            padding-bottom: 2rem;
    
            .mark-sheet-desc {
                margin-right: 0;
    
                .label-sub-heading {
                    font-size: 1.25rem;
                }
    
                .label-text {
                    font-size: 1rem;
                }
            }
    
            .mark-sheet-img {
                width: 100%;
                margin-top: 1.5rem;
                border: 2px solid #D9D9D9;
            }
        }

        .label-bullet-text {
            font-size: 1rem;
            width: 95%;
            padding: 0 0 0 1rem;

            &.light {
                font-size: 1rem;
            }

            .bullet-item {

                &::marker {
                    font-size: 1.25rem;
                }
            }
        }

        .expirence-container {
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 3rem;
            gap: 2rem;
            
            &.education {
                align-items: flex-start;
                
                .start-date {
                    width: 100px;
                    height: max-content;
                }

                .end-date {
                    width: 100px;
                    height: max-content;
                    top: 0;
                    right: 0;
                }
            }

            .date-container {
                height: 2.5rem;
                width: 280px;

                &.timeline {
                    width: 210px;
                    height: 2.5rem;

                    .start-date {
                        width: 100px;
                        height: max-content;
                        padding: 0.5rem 0;
                    }
    
                    .end-date {
                        width: 100px;
                        height: max-content;
                        padding: 0.5rem 0;
                        top: 0;
                        right: 0;
                    }
                }

                .start-date {
                    width: 100px;
                    height: max-content;
                }

                .end-date {
                    width: 100px;
                    height: max-content;
                    top: 0;
                    right: 0;
                }
            }

            .date-container-education {
                width: 150px;
                height: 50px;
                background-color: #DFDFDF;
                display: grid;
                place-items: center;
    
                .start-date-education {
                    font-size: 1.25rem;
                }
            }
        }

        .pink-container {
            padding: 1.25rem;
    
            .read-more {
                font-size: 1.25rem;
            }
        }

        .about-more-cont {
            flex-direction: column;
        }
    }

    .blue-container {
        padding: 1rem;

        .label-text {
            font-size: 1rem;
        }
    }

    .footer-banner {
        padding: 1rem;

        .banner-text {
            font-size: 1rem;
            margin-bottom: 1rem;

            &.padding-bt-2 {
                padding-bottom: 2rem;
            }
        }

        .download-profile {
            right: 0.5rem;
            bottom: 0.5rem;

            .download-icon {
                width: 1.5rem;
                height: auto;
            }

            .download-text {
                font-size: 0.5rem;
            }
        }
    }

    .bottom-btn-container {
        padding: 1rem;

        .navigate-button {
            font-size: 1.25rem;
        }

        .pages-button-cont {
            gap: 1.5rem;
        }
    }
}