.help-container {
    padding: 4rem;
    font-family: Arial, sans-serif;
    text-align: center;
    max-width: 80%;
    margin: 0 auto;
  
    h2 {
      font-size: 4rem;
      margin-bottom: 10px;
    }
  
    p {
      font-size: 1.4rem;
      margin-bottom: 20px;
    }
  
    .contact-section {
        display: flex;
        flex-direction: column;
        gap: 20px;
        max-width: 50%;
        margin: 0 auto;
  
      .contact-block {
        padding: 20px;
        border: 2px solid #e8e8e8;
        border-radius: 10px;
  
        h3 {
          font-size: 2rem;
          margin-bottom: 10px;
          color: #D59847;
          &:after {
            content: '';
            display: block;
            width: 50%;
            height: 2px;
            background-color: #D59847;
            margin: 8px auto 0;
          }
        }
  
        p {
          font-size: 16px;
          margin: 5px 0;
  
          a {
            color: #00aaff;
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
  
  @media (min-width: 600px) {
    .contact-section {
      flex-direction: row;
      justify-content: space-between;
  
      .contact-block {
        flex: 1;
        margin: 0 10px;
      }
    }
  }
  
  .contact-form-container {
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    max-width: 50%;
    margin: 20px auto 0;
    text-align: left;

    form {
      display: flex;
      flex-direction: column;

      .form-group {
        margin-bottom: 15px;

        label {
          display: block;
          font-size: 1rem;
          color: #333;
          margin-bottom: 5px;
        }

        input,
        textarea {
          width: 100%;
          padding: 10px;
          border: 1px solid #ccc;
          border-radius: 4px;
          font-size: 1rem;
        }

        textarea {
          resize: vertical;
        }
      }

      .disclaimer {
        font-size: 0.9rem;
        color: #666;
        margin-bottom: 20px;
      }

      .submit-button {
        padding: 10px 20px;
        background-color: #d4a14e;
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 1rem;
        align-self: center;
        cursor: pointer;

        &:hover {
          background-color: #b8873e;
        }
      }
    }

    @media (max-width: 768px) {
      padding: 15px;

      .form-group {
        label {
          font-size: 0.9rem;
        }

        input,
        textarea {
          font-size: 0.9rem;
        }
      }

      .submit-button {
        font-size: 0.9rem;
        padding: 8px 16px;
      }
    }

    @media (max-width: 480px) {
      padding: 10px;

      .form-group {
        label {
          font-size: 0.8rem;
        }

        input,
        textarea {
          font-size: 0.8rem;
        }
      }

      .submit-button {
        font-size: 0.8rem;
        padding: 6px 12px;
      }
    }
  }

@media screen and (max-width: 961px) {
  .help-container {
    padding: 1rem;
    // margin-top: 8rem;

    h2 {
      font-size: 1.5rem;
    }

    p {
      font-size: 1rem;
      line-height: 1.5rem;
    }

    .contact-section {
      max-width: 100%;
      margin: 3rem 0;

      .contact-block {
        padding: 1rem;

        h3 {
          font-size: 1.5rem;
          margin: 0 0 2rem 0;
        }
      }
    }
  }

  .contact-form-container {
    margin: 1rem 0;
    padding: 0 2rem;
    max-width: 100%;

    .form-group {
      max-width: 90%;
      
      input {
        width: 100%;
      }
    }

    .disclaimer {
      line-height: 1.5rem;
    }
  }
}