.tab-container {
    display: flex;
    justify-content: space-around; 
    gap: 2rem;
    margin-bottom: 2rem;
  
    .tab-item {
      font-size: 1.75rem;
      font-weight: 600;
      color: #D1D1D1;
      padding: 0.5rem 2rem;
      border: 2px solid #C3C3C3;
      transition: color 0.3s ease, border-color 0.3s ease;
      border-radius: 9px;
      text-align: center;
  
      &:hover {
        cursor: pointer;
      }
  
      &.active {
        color: #D59847;
        border-color: #D59847;
        box-shadow: 0 4px 4px #00000040;
      }
    }
  
    &.aboutfica {
      justify-content: space-between;
  
      .tab-item {
        font-size: 1.4rem; 
        padding: 0.5rem 3.5rem; 
      }
    }
  }
  

.profile-img-container {
    display: flex;
    gap: 3rem;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 2rem;

    img {
      max-height: 8rem;
    }

    &.networkingcountries {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;

      img {
        max-height: 8rem;
      }
      
      .image-title {
        font-size: 1rem;
        font-weight: 600;
        margin: 0.5rem 0;
        width: 65%;
      }
    }
}


@media screen and (max-width: 961px) {
  .tab-container {
    padding: 0 1.25rem;

    .tab-item {
      font-size: 1rem;
      padding: 0.25rem;
    }

    &.aboutfica {
      padding: 0;

      .tab-item {
        display: grid;
        place-items: center;
        font-size: 0.8rem;
        padding: 0 0.25rem;
      }
    }
  }

  .profile-img-container {

    img {
      max-width: 250px;
    }

    &.networkingcountries {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;

      img {
        max-height: 250px;
      }
      
      .image-title {
        font-size: 0.75rem;
      }
    }
  }
}

@media screen and (max-width: 460px) {
  .tab-container {
    padding: 0 1rem;
    gap: 0.5rem;
  }
}