.solution-hero {
  background-color: #0E0E0E;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  .hero-text {
    font-size: 3.5rem;
    color: #FFFFFF;
    margin: 0;
    padding-left: 3rem;
  }
}

.solution-hero-baner-cont {
  background-image: linear-gradient(to right, #FFFFFF , #D59847, #FFFFFF);
  text-align: center;

  .solution-hero-baner-text {
    font-size: 2rem;
    margin: 0;
    padding: 1rem 0;
  }
}

.ficasolutions-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 50px 0 50px; 

    .section-image {
      width: 100%;
    }
  }
  
  .ficasolutions-logo {
    max-width: 50%;
    margin-bottom: 20px; 
    margin-top: 50px;
  }
  
  .ficasolutions-content {
    text-align: left;
    width: 100%; 
  }
  
  .ficasolutions-title {
    font-size: 2.5rem;
    color: #D59847;
    margin-top: 20px;
  }
  
  p {
    margin-top: 30px; 
    font-size: 1.2rem;
    position: relative;
  }
  
  .bullet-point {
    width: 10px;
    height: 10px;
    background-color: #D59847;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
    position: absolute;
    left: -20px;
  }
  
  .ficasolutions-ul {
    margin-top: 30px;
    font-size: 1.2rem;
    list-style-type: disc;
    position: relative;
    padding-left: 20px; 
  }
  
  .ficasolutions-li {
    margin-top: 30px;
    position: relative;
    // padding-left: 20px; 
    
    &::marker {
      color: #D59847;
      font-size: 1.5rem;
    }
    // &::before {
    //   content: '';
    //   width: 10px;
    //   height: 10px;
    //   background-color: #D59847;
    //   border-radius: 50%;
    //   display: inline-block;
    //   position: absolute;
    //   left: -20px;
    //   top: 50%;
    //   transform: translateY(-50%);
    // }
  }

  @media screen and (max-width: 961px) {
    .ficasolutions-ul {
      font-size: 1rem;

      .ficasolutions-li {
        font-size: 1rem;

        &::marker {
          font-size: 1.25rem;
        }
      }
    }
  }
.ficasolutions-subheading{
    font-weight: bold;
    font-size: 1.7rem;
    margin-bottom: 0;
}

@media screen and (max-width: 961px) {
  .solution-hero {

    .hero-text {
      font-size: 1.5rem;
      padding: 1rem;
    }

    img {
      display: none;
    }
  }

  .solution-hero-baner-cont {
    .solution-hero-baner-text {
      font-size: 1.3rem;
      padding: 1rem;
    }
  }

  .ficasolutions-container {
    margin: 0;
    padding: 0 1rem;

    .ficasolutions-title {
      font-size: 1.5rem;
    }

    p {
      font-size: 1rem;
      margin-top: 0;
      line-height: 1.5rem;
    }

    .ficasolutions-logo {
      max-width: 100%;
    }
  }
  .ficasolutions-subheading {
    font-size: 1.25rem;
  }
}