.aboutfica-container {
    display: flex;
    flex-direction: row;

    .aboutfica-menu-container {
      display: flex;
      flex-direction: column;
      padding: 2rem 0 0 2rem;
  
    }
    
    .custom-menu {
      width: 256px;
      border: 1px solid #ccc;
      border-radius: 8px;
      overflow: hidden;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
    
    .menu-item {
      display: flex;
      align-items: center;
      padding: 12px;
      cursor: pointer;
      transition: background-color 0.3s ease;
      border-bottom: 1px solid #ddd;
    }
    
    .menu-item:last-child {
      border-bottom: none;
    }
    
    .menu-item:hover {
      background-color: #f0f0f0;
    }
    
    .menu-item.active {
      background-color: #e6f7ff;
    }
    
    .menu-item span {
      flex: 1;
    }
    
    .menu-icon {
      margin-left: auto;
    }
  }
  
.about-banner {
  display: flex;
  flex-grow: 1;
  margin-left: 2rem; 
  position: relative;

  .menu-icon-sm {
    display: none;
  }
      
  .about-banner-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    position: relative;
  }
  
  .image-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  
  .image-text h1 {
    font-size: 2.5rem;
    color: white;
  }

}
.aboutfica-content-wrapper {
  .padding-4 {
    padding: 0 4rem;
  }

  h1 {
    font-size: 2rem;
    color: #d4a14e;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 2rem;
    color: #d4a14e;
    margin-bottom: 20px;
  }

  p {
    font-size: 1.25rem;
    line-height: 2rem;
    font-weight: 400;
    word-spacing: 0.4rem;
    color: #000000;
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    padding: 15px;

    // h1 {
    //   font-size: 1.5rem;
    // }

    // p {
    //   font-size: 0.9rem;
    // }
  }

  @media (max-width: 480px) {
    padding: 10px;

    // h1 {
    //   font-size: 1.2rem;
    // }

    // p {
    //   font-size: 0.8rem;
    // }
  }
}
.about-partner-container {
  display: flex;
  gap: 4rem;
  flex-wrap: wrap;
  align-items: center;
}

.about-partner-container img {
  max-width: 50%;
  max-height: 20rem;
}

.about-partner-container.development {
  gap: 2rem;
}

.about-partner-container.development img {
  max-width: 25%;
}


@media screen and (max-width: 961px) {
  .sub-title-about {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
  .aboutfica-container {
    // margin: 4rem 0 0 0;

    .aboutfica-menu-container {
      padding: 0;
      
      .custom-menu {
        display: none;
      }
    }

    .about-banner {
      max-height: 150px;
      margin: 0;
      position: relative;

      .menu-icon-sm {
        display: block;
        position: absolute;
        top: 1.25rem;
        left: 1rem;
        cursor: pointer;
      }

      .about-banner-image {
        min-height: 200px;

        .image-text {
          
          h1 {
            font-size: 1.25rem;
          }
        }
      }
    }
  }

  .aboutfica-content-wrapper {

    h1 {
      margin-top: 4rem;
      padding: 0 1rem !important;
      font-size: 1.5rem;
    }

    h2 {
      font-size: 1.25rem;
      line-height: 1.7rem;
    }
    
    .padding-4 {
      padding: 0 1rem;

      &.padding-4-sm {
        padding: 0 !important;

        p {
          font-weight: 500 !important;
        }
      }

      p {
        padding: 0rem !important;
        font-size: 1rem;
      }

      .padding-sm {
        padding: 0 !important;

        p {
          padding: 0 1rem !important;
          line-height: 1.75rem;
        }
      }

      ul {
        padding: 1rem;

        li {
          font-size: 1rem;
          line-height: 1.75rem;
          margin-bottom: 1rem;

          p {
            padding: 0 !important;
            line-height: 1.75rem;
          }

        }
      }

      .line-height {
        line-height: 1.5rem !important;
      }
    }

    p {
      line-height: 1.75rem;
    }

    .subheading-data {

      .fundraising-body-numbering {
        align-items: center;
        aspect-ratio: 1 / 1;
        border-radius: 50%;
        display: flex;
        height: 1.25rem;
        justify-content: center;
        margin-right: 1rem;
        font-size: 1rem;
      }
  
      .fundraising-body-data {
        font-size: 0.75rem !important;
        line-height: 1.25rem;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .subheading-content-wrapper {
    padding: 0;

    .subheading-data {
      flex-direction: column;
      gap: 0.5rem;

      .fundraising-body-numbering {
        border-radius: 0;
        background-color: transparent;
        background-image: linear-gradient(to right, #D59847, #d5974700);
        padding: 0.1rem 1.25rem 0.1rem 1rem;
        justify-content: flex-start;
        font-size: 1.25rem;
      }
      
      .fundraising-body-data {
        padding: 0 1rem;
      }
    }
  }
}